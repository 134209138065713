<template>
<Page id="profile" :title="ProfileTitle">
      <Loader v-if="loading" loadingtext="Läser in profil..."></Loader>
      <div v-else>
            <div class="my-3 text-center">
                  <h3>Profilbild</h3>
            </div>
            <Loader v-if="ImageLoading" loadingtext="Vänta..."></Loader>
            <b-row no-gutters v-else class="align-items-center">
                  <b-col cols="12" lg="4" class="text-center">
                        <b-img v-if="ShowProfileImage && Avatar" :src="Avatar"></b-img>
                        <br />
                        <b-button class="mt-2" @click="Rotate()" variant="primary">
                              <inline-svg :src="svg('common/rotate-cw.svg')" width="20px" height="20px" class="upload-file-svg mb-1" />
                              <span class="ml-2">Rotera</span>
                        </b-button>
                  </b-col>
                  <b-col cols="12" lg="8" class="mt-3 mt-lg-0">
                        <file-upload ref="uploadZone" :drop="true" v-model="file" @input-filter="inputFilter" @input-file="inputFile">
                              <b-row no-gutters class="border-dashed rounded">
                                    <b-col>
                                          <b-row no-gutters class="justify-content-center align-items-center py-3">
                                                <b-col cols="12" md="4">
                                                      <b-button class="" variant="primary">
                                                            <inline-svg :src="svg('common/upload.svg')" width="20px" height="20px" class="upload-file-svg mb-1" />
                                                            <span class="ml-2">Ladda upp fil</span>
                                                      </b-button>
                                                </b-col>
                                                <b-col cols="4" class="d-none d-lg-block"> - eller - </b-col>
                                                <b-col cols="4" class="d-none d-lg-block font-italic">
                                                      Dra och släpp en fil innanför markeringen
                                                </b-col>
                                          </b-row>
                                    </b-col>
                              </b-row>
                        </file-upload>
                  </b-col>
            </b-row>
            <Alert class="mt-3" v-for="(error, index) in fileerrors" :key="index" :variant="error.variant" >{{error.text}}</Alert>
            <hr />
            <div class="my-3 text-center">
                  <h3>Dina uppgifter</h3>
            </div>
            <form class="p-1 mb-4">
                  <b-row no-gutters class="mb-2 align-items-center">
                        <b-col cols="12" md="6" class="font-weight-bold">
                              {{ GetFieldTitle("email") }} (inloggning)
                        </b-col>
                        <b-col cols="12" md="6">
                              <!-- <b-input v-model="profile.email" type="email" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveProfileField('email',$event)"></b-input> -->
                              {{ profile.email }}
                        </b-col>
                  </b-row>
                  <b-row no-gutters class="mb-2 align-items-center">
                        <b-col cols="12" md="6" class="font-weight-bold">
                              {{ GetFieldTitle("password") }}
                        </b-col>
                        <b-col cols="12" md="6">
                              <b-button v-b-modal.newpasswordmodal variant="primary">Ändra</b-button>
                        </b-col>
                  </b-row>
                  <b-row no-gutters class="mb-2 align-items-center">
                        <b-col cols="12" md="6" class="font-weight-bold">
                              {{ GetFieldTitle("username") }}
                        </b-col>
                        <b-col cols="12" md="6">
                              <b-input v-model="profile.username" type="text" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveProfileField('username', $event)"></b-input>
                        </b-col>
                  </b-row>
                  <b-row no-gutters class="mb-2 align-items-center">
                        <b-col cols="12" md="6" class="font-weight-bold">
                              {{ GetFieldTitle("name") }}
                        </b-col>
                        <b-col cols="12" md="6">
                              <b-input v-model="profile.name" type="text" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveProfileField('name', $event)"></b-input>
                        </b-col>
                  </b-row>
                  <b-row no-gutters class="mb-2 align-items-center">
                        <b-col cols="12" md="6" class="font-weight-bold">
                              {{ GetFieldTitle("lastname") }}
                        </b-col>
                        <b-col cols="12" md="6">
                              <b-input v-model="profile.lastname" type="text" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveProfileField('lastname', $event)"></b-input>
                        </b-col>
                  </b-row>

                  <b-row no-gutters class="mb-2 align-items-center">
                        <b-col cols="12" md="6" class="font-weight-bold">
                              {{ GetFieldTitle("phone") }} (för sms/kontakt)
                        </b-col>
                        <b-col cols="12" md="6">
                              <b-input v-model="profile.phone" type="text" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveProfileField('phone', $event)"></b-input>
                        </b-col>
                  </b-row>
                  <b-row no-gutters class="mb-2 align-items-center">
                        <b-col cols="12" md="6" class="font-weight-bold">
                              {{ GetFieldTitle("ssnr") }} (får åldern)
                        </b-col>
                        <b-col cols="12" md="6">
                              <b-input v-model="profile.ssnr" type="text" v-on:keyup.enter="focusNext($event.target)" @focus="$event.target.select()" @change="SaveProfileField('ssnr', $event)"></b-input>
                        </b-col>
                  </b-row>
            </form>

            <hr />
            <!-- <div class="my-3 text-center">
                  <h3>Din kalender</h3>
            </div>
            <div>
                  Prenumerera på {{PageName}}-kalendern för att inte missa att tipsa matcher! Kalendern är av typen <a href="https://sv.wikipedia.org/wiki/ICalendar">iCal</a> och kan öppnas på både iPhone och Android-telefoner.
                  <div class="mt-4 text-center">
                        <a target="_blank" :href="CalendarHref" class="btn btn-primary" @click="DownloadCalendar()">Hämta din kalender</a>
                  </div>
            </div> -->

            <b-modal @shown="focusPword" title="Nytt lösenord" id="newpasswordmodal" ref="newpasswordmodal" hide-footer>
                  <div class="text-center">
                        <h3>Välj nytt lösenord</h3>
                  </div>
                  <div>
                        <label for="user-pword">Nytt lösenord</label>
                        <b-form-input placeholder="Välj ditt nya lösenord" ref="newpwordbox" type="password" id="user-pword" v-model="newpassword" class="mb-2"></b-form-input>
                  </div>
                  <div class="mt-4 mb-4 text-center">
                        <b-button @click="Close()" variant="secondary" class="m-1">Avbryt</b-button>
                        <b-button @click="SavePassword()" variant="primary" class="m-1">Spara</b-button>
                  </div>
            </b-modal>
      </div>
</Page>
</template>

<script>
import FileUpload from "vue-upload-component";
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
      name: "Profile",
      components: {
            FileUpload,
            Alert,
            Page,
            Loader,
      },
      data() {
            return {
                  file: [],
                  fileerrors: [],
                  ShowProfileImage: true,
                  newpassword: "",
                  orgdata: false,
            };
      },
      metaInfo() {
            return {
                  title: this.ProfileTitle,
                  meta: [{
                        name: "description",
                        content: "Ändra dina uppgifter!",
                  }, ],
            };
      },
      computed: {
            CalendarHref() {
                  var url = process.env.VUE_APP_CALENDAR_URL;
                  return url + "data/calendar/" + this.profile.email + ".ics";
            },
            PageName() {
                  return process.env.VUE_APP_NAME;
            },
            ImageLoading() {
                  return (
                        this.$store.getters["profilep/imageloading"] ||
                        this.$store.getters["profilep/rotateloading"]
                  );
            },
            ProfileTitle() {
                  if (this.loading || !this.profile) return "Laddar...";
                  return this.profile.username;
            },
            loading() {
                  return this.$store.getters["profileg/loading"];
            },
            profile() {
                  return this.$store.getters["profileg/profile"];
            },
            Avatar() {
                  if (this.profile)
                        return this.$images.userAvatar(
                              this.profile.id,
                              this.profile.imgversion
                        );
                  return false;
            },
      },
      methods: {
            GetFieldTitle(key) {
                  switch (key) {
                        case "name":
                              return "Förnamn";
                        case "username":
                              return "Användarnamn";
                        case "email":
                              return "E-postadress";
                        case "lastname":
                              return "Efternamn";
                        case "phone":
                              return "Mobilnummer";
                        case "ssnr":
                              return "Personnummer";
                        case "password":
                              return "Lösenord";
                        default:
                              return key;
                  }
            },
            SaveProfileField(key, value) {
                  var postobj = {
                        key: key,
                        value: value,
                  };

                  this.$store
                        .dispatch("profilep/SaveProfileField", postobj)
                        .then((response) => {
                              var textkey = this.GetFieldTitle(key);
                              if (response.data.status == "ok") {
                                    let title = "Ändrat!";
                                    let text = textkey + " har ändrats till '" + value + "'";
                                    this.ShowToast(title, text, "success");
                              } else {
                                    for (var id in response.data.errors) {
                                          let error = response.data.errors[id];
                                          let title = "Fel!";
                                          let text = error;
                                          this.ShowToast(title, text, "danger");
                                    }
                                    this.$store.commit("profileg/setByKey", {
                                          key: key,
                                          value: this.orgdata[key]
                                    });
                              }
                        })
                        .catch(() => {
                              var textkey = this.GetFieldTitle(key);
                              var title = "Fel!";
                              var text = textkey + " kunde inte ändras till " + value + "!";
                              this.$store.commit("profileg/setByKey", {
                                    key: key,
                                    value: this.orgdata[key]
                              });
                              this.ShowToast(title, text, "danger");
                        });
            },
            focusNext(elem) {
                  const currentIndex = Array.from(elem.form.elements).indexOf(elem);
                  elem.form.elements
                        .item(
                              currentIndex < elem.form.elements.length - 1 ? currentIndex + 1 : 0
                        )
                        .focus();
            },
            Rotate() {
                  this.ShowProfileImage = false;
                  this.$store.dispatch("profilep/RotateImage").then(() => {
                        // roterad eller error
                        this.getUser();
                        this.ShowProfileImage = true;
                  });
            },
            async uploadFile(file) {
                  file.loading = true;
                  this.$refs.uploadZone.update(file);
                  this.ShowProfileImage = false;

                  try {
                        await this.DoUploadFile(file.file);
                        this.getUser();
                        this.ShowProfileImage = true;
                  } catch (exception) {
                        let error = {
                              text: "Någonting gick med uppladdning av filen: " +
                                    file.name +
                                    ". Var god försök igen.",
                              variant: "danger",
                        };

                        this.fileerrors.push(error);
                        this.ShowProfileImage = true;
                        throw exception;
                  }

                  this.$refs.uploadZone.update(file, {
                        loading: false,
                  });
            },
            async inputFilter(newFile, oldFile, prevent) {
                  this.fileerrors = [];
                  if (newFile && !oldFile) {
                        // add

                        if (newFile.name && !/\.(jpg|jpeg|png|bmp|heic)$/i.test(newFile.name)) {
                              let error = {
                                    text: "Endast Bild-filer är tillåtna. (" + newFile.name + ")",
                                    variant: "danger",
                              };
                              this.fileerrors.push(error);
                              return prevent();
                        } else if (newFile.size && newFile.size > 5242880) {
                              let error = {
                                    text: "Filen " +
                                          newFile.name +
                                          " är för stor. Filer får max vara 5 MB.",
                                    variant: "danger",
                              };
                              this.fileerrors.push(error);
                              return prevent();
                        }
                        await this.uploadFile(newFile);
                  }
            },
            async inputFile() {},
            async DoUploadFile(file) {
                  let successfulUpload = false;

                  await this.$store
                        .dispatch("profilep/uploadFile", file)
                        .then((response) => {
                              if (response.status == 200) {
                                    successfulUpload = true;
                              } else {
                                    // Felhantering :)
                              }
                        });

                  return successfulUpload;
            },
            svg(path) {
                  return this.$images.svg(path);
            },
            getUser() {
                  this.$store.dispatch("profileg/getProfile").then((resp) => {
                        this.orgdata = JSON.parse(JSON.stringify(resp));
                  });
            },
            ShowToast(title, text, variant) {
                  this.$bvToast.toast(text, {
                        title: title,
                        variant: variant,
                        solid: true,
                  });
            },
            focusPword() {
                  this.$refs.newpwordbox.focus();
            },
            SavePassword() {
                  this.SaveProfileField("password", this.newpassword);
                  this.Close();
            },
            Close() {
                  this.$refs.newpasswordmodal.hide();
            },
      },
      mounted() {
            this.getUser();
      },
};
</script>
